// a function that removes all \n and \t and html tags from a string
const removeHtmlTagsAndWhitespace = string => {

	string = string.replace(/<[^>]*>?/gm, "").replace(/\n/gm, "").replace(/\t/gm, "")
	string = string.trim()

	return string
}

/**
 * add_shipping_info event
 */

// trigger when #shipping_method is changed
jQuery("form.woocommerce-checkout").on("change", "#shipping_method", (event) => {

	if (!event.target?.value) return
	if (!event.target?.id) return

	triggerAddShippingInfoEvent({
		slug: event.target.value,
		text: removeHtmlTagsAndWhitespace(jQuery("label[for='" + event.target.id + "']").text()),
	})
})

// trigger when a shipping method is already selected on page load
jQuery(() => {
	if (jQuery("#shipping_method").length) {

		// get the checked shipping method from #shipping_method
		let checkedShippingMethod = jQuery("#shipping_method").find(":checked")

		// if there is a checked shipping method
		if (checkedShippingMethod.length) {

			if (!checkedShippingMethod.val()) return
			if (!checkedShippingMethod.attr("id")) return

			triggerAddShippingInfoEvent({
				slug: checkedShippingMethod.val(),
				text: removeHtmlTagsAndWhitespace(jQuery("label[for='" + checkedShippingMethod.attr("id") + "']").text()),
			})
		}
	}
})

// trigger when a shipping method is selected on page load
const triggerAddShippingInfoEvent = shippingTier => {
	jQuery(document).trigger("pmw:add-shipping-info", {shippingTier})
}

/**
 * add_payment_info event
 */

// trigger when a payment method is selected
jQuery("form.woocommerce-checkout").on("change", "input[name=\"payment_method\"]", event => {

	if (!event.target.value) return
	if (!event.target.id) return

	triggerAddPaymentInfoEvent({
		slug: event.target.value,
		text: removeHtmlTagsAndWhitespace(jQuery("label[for='" + event.target.id + "']").text()),
	})
})

// trigger when a payment method is already selected on page load
jQuery(() => {

	let checkedPaymentMethod = jQuery("input[name=\"payment_method\"]:checked")

	if (checkedPaymentMethod.length) {

		if (!checkedPaymentMethod.val()) return
		if (!checkedPaymentMethod.attr("id")) return

		triggerAddPaymentInfoEvent({
			slug: checkedPaymentMethod.val(),
			text: removeHtmlTagsAndWhitespace(jQuery("label[for='" + checkedPaymentMethod.attr("id") + "']").text()),
		})
	}
})

// trigger when a payment method is selected on page load
const triggerAddPaymentInfoEvent = paymentType => {
	jQuery(document).trigger("pmw:add-payment-info", {paymentType})
}

/**
 * remove_from_cart event
 *
 * Cannot be attached directly because the mini cart doesn't necessarily contain the remove button on page load.
 */
jQuery(document).on("click", ".remove_from_cart_button, .remove", (event) => {

	// console.log("remove_from_cart event" + new Date().getTime())

	try {

		let url       = new URL(jQuery(event.currentTarget).attr("href"))
		let productId = wpm.getProductIdByCartItemKeyUrl(url)

		wpm.removeProductFromCart(productId)

	} catch (e) {
		console.error(e)
	}
})


/**
 * begin_checkout event
 *
 * Cannot be attached directly because the mini cart doesn't necessarily contain the remove button on page load.
 */
let checkoutButtonClasses = [
	// ".checkout", // this is too generic. It triggers on the checkout page on some themes fore each interaction with the checkout form.
	".checkout-button",
	".cart-checkout-button",
	".button.checkout",
	".xoo-wsc-ft-btn-checkout", // https://xootix.com/side-cart-for-woocommerce/
	".elementor-button--checkout",
	".xt_woofc-checkout", // https://wordpress.org/plugins/woo-floating-cart-lite/
	".fkcart-checkout--text", // https://funnelkit.com/
]

const checkoutButtonSelectors = wpm.prepareSelectors(checkoutButtonClasses, "beginCheckout")

// https://wordpress.stackexchange.com/a/352171/68337
jQuery(document).on("click init_checkout", checkoutButtonSelectors, () => {
	jQuery(document).trigger("pmw:begin-checkout")
})

jQuery(document).on("updated_cart_totals", () => {
	jQuery(document).trigger("pmw:view-cart")
})

/**
 * Set up PWM events
 */

// track checkout option event: purchase click
// https://wordpress.stackexchange.com/a/352171/68337
jQuery(document).on("wpmLoad", (event) => {
	jQuery(document).on("payment_method_selected", () => {

		if (false === wpm.paymentMethodSelected) {
			wpm.fireCheckoutProgress(3)
		}

		wpm.fireCheckoutOption(3, jQuery("input[name='payment_method']:checked").val())
		wpm.paymentMethodSelected = true
	})
})

// populate the wpmDataLayer with the cart items
jQuery(document).on("wpmLoad", () => {

	try {
		wpm.initCart()
	} catch (e) {
		console.error(e)
	}
})

// get all add-to-cart= products from backend
jQuery(document).on("wpmLoad", () => {

	wpmDataLayer.products = wpmDataLayer.products || {}

	// scan page for add-to-cart= links
	let productIds = wpm.getAddToCartLinkProductIds()

	wpm.getProductsFromBackend(productIds)
})

/**
 * Save the referrer into a cookie
 *
 * @returns {void}
 */
jQuery(document).on("wpmLoad", () => {

	// If there is no referrer, then return
	if (!document.referrer) {
		return
	}

	// If the referrer is already stored, then return
	if (wpm.retrieveData("referrer")) {
		return
	}

	let referrerUrl      = new URL(document.referrer)
	let referrerHostname = referrerUrl.hostname

	if (referrerHostname !== window.location.host) {
		wpm.storeData("referrer", referrerHostname)
	}
})

/**
 * Collect parameters from the URL
 * and save them to the PMW session storage.
 * Parameters:
 * - gclid
 * - wbraid
 * - gbraid
 * - fbclid
 * - ttclid
 * - ScCid
 * - epik
 *
 * @returns {void}
 * */
jQuery(document).on("wpmLoad", () => {

	let gclid = wpm.getUrlParameter("gclid")
	if (gclid) wpm.storeData("gclid", gclid)

	let wbraid = wpm.getUrlParameter("wbraid")
	if (wbraid) wpm.storeData("wbraid", wbraid)

	let gbraid = wpm.getUrlParameter("gbraid")
	if (gbraid) wpm.storeData("gbraid", gbraid)

	// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
	let fbclid = wpm.getUrlParameter("fbclid")
	if (fbclid) {
		fbclid = "fb.1." + Math.floor(Date.now() / 1000) + "." + fbclid
		wpm.storeData("fbclid", fbclid)
	}

	let ttclid = wpm.getUrlParameter("ttclid")
	if (ttclid) wpm.storeData("ttclid", ttclid)

	let scid = wpm.getUrlParameter("ScCid")
	if (scid) wpm.storeData("scid", scid)

	let epik = wpm.getUrlParameter("epik")
	if (epik) wpm.storeData("epik", epik)
})

/**
 * Create our own load event in order to better handle script flow execution when JS "optimizers" shuffle the code.
 */
jQuery(document).on("wpmLoad", () => {
	// document.addEventListener("wpmLoad", function () {
	try {
		if (typeof wpmDataLayer != "undefined" && !wpmDataLayer?.pmw_loaded) {

			jQuery(document).trigger("pmw:load-always")

			if (wpmDataLayer?.shop) {
				if (
					"product" === wpmDataLayer.shop.page_type &&
					"variable" !== wpmDataLayer.shop.product_type &&
					wpm.getMainProductIdFromProductPage()
				) {
					let product = wpm.getProductDataForViewItemEvent(wpm.getMainProductIdFromProductPage())
					jQuery(document).trigger("pmw:view-item", product)
				} else if ("product_category" === wpmDataLayer.shop.page_type) {
					jQuery(document).trigger("pmw:view-category")
				} else if ("search" === wpmDataLayer.shop.page_type) {
					jQuery(document).trigger("pmw:search")
				} else if ("cart" === wpmDataLayer.shop.page_type) {
					jQuery(document).trigger("pmw:view-cart")
				} else if ("order_received_page" === wpmDataLayer.shop.page_type) {
					if (wpmDataLayer?.order && !wpm.isOrderIdStored(wpmDataLayer.order.id)) {
						jQuery(document).trigger("pmw:view-order-received-page")
						wpm.writeOrderIdToStorage(wpmDataLayer.order.id, wpmDataLayer.order.key)
						if (typeof wpm.acrRemoveCookie === "function") wpm.acrRemoveCookie()
					}
				} else {
					jQuery(document).trigger("pmw:everywhere-else")
				}
			} else {
				jQuery(document).trigger("pmw:everywhere-else")
			}

			if (wpmDataLayer?.user?.id && !wpm.hasLoginEventFired()) {
				jQuery(document).trigger("pmw:login")
				wpm.setLoginEventFired()
			}

			wpmDataLayer.pmw_loaded = true
		}

	} catch (e) {
		console.error(e)
	}
})

jQuery(document).on("wpmLoad", async () => {
	if (wpm.retrieveData("restEndpointAvailable") === false) pmw.console.error("REST endpoint is not available. Using admin-ajax.php instead.")
})

/**
 * All s2s events
 */

/**
 * Add to cart event
 */
jQuery(document).on("pmw:add-to-cart", (event, product) => {

	pmw.console.log("pmw:add-to-cart event fired", {event, product})

	/**
	 * Prepare the payload
	 */

	let payload = {
		event  : "add_to_cart",
		product: product,
	}

	// Facebook
	// If Facebook pixel is loaded, add Facebook server to server event data to the payload
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "AddToCart",
			event_id        : wpm.getFbRandomEventId(),
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : wpm.fbGetProductDataForCapiEvent(product),
		}

		if (wpm.retrieveData("referrer")) {
			payload.facebook.referrer_url = wpm.makeFullUrl(wpm.retrieveData("referrer"))
		}
	}

	// Pinterest
	// https://developers.pinterest.com/docs/conversions/best/
	// https://developers.pinterest.com/docs/api/v5/#operation/events/create
	// https://developers.pinterest.com/docs/conversions/event/
	if (wpmDataLayer?.pixels?.pinterest?.loaded) {

		payload.pinterest = wpm.getPinterestS2SBaseData()

		payload.pinterest.event_name  = "addtocart"
		payload.pinterest.custom_data = wpm.pinterestGetProductDataForCapiEvent(product)
	}

	// TikTok
	// https://ads.tiktok.com/gateway/docs/index?identify_key=c0138ffadd90a955c1f0670a56fe348d1d40680b3c89461e09f78ed26785164b&language=ENGLISH&doc_id=1739585702922241#item-link-Adding%20parameters%20to%20event%20code
	// TODO: add content_category to product data
	// TODO: add brand to product data
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event     : "AddToCart",
			event_id  : wpm.getRandomEventId(),
			user      : wpm.tiktokGetEventUserData(),
			page      : wpm.tiktokGetEventPageData(),
			properties: {
				value       : product.price * product.quantity,
				currency    : product.currency,
				content_type: "product",
				contents    : [{
					content_id  : wpm.getProductIdForSpecificPixel(product, "tiktok"),
					content_name: product.name,
					quantity    : product.quantity,
					price       : product.price,
				}],
			},
		}
	}

	// Snapchat
	// https://docs.snap.com/api/marketing-api/Conversions-API/Parameters
	// https://businesshelp.snapchat.com/s/article/pixel-direct-implementation
	if (wpmDataLayer?.pixels?.snapchat?.loaded) {
		payload.snapchat             = wpm.getSnapchatS2SBaseData()
		payload.snapchat.event_name  = "ADD_CART"
		payload.snapchat.custom_data = wpm.getSnapchatCustomDataForProduct(product)
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("pmw:s2s:add-to-cart", payload)

	/**
	 * Process the server-to-server event
	 */

	wpm.sendEventPayloadToServer(payload)
})

/**
 * Begin checkout event
 */
jQuery(document).on("pmw:begin-checkout", (event) => {

	pmw.console.log("pmw:begin-checkout event fired", {event})

	/**
	 * Prepare the payload
	 */

	let payload = {
		event: "begin_checkout",
	}

	// Facebook
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "InitiateCheckout",
			event_id        : wpm.getFbRandomEventId(),
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : {},
		}

		if (wpm.retrieveData("referrer")) {
			payload.facebook.referrer_url = wpm.makeFullUrl(wpm.retrieveData("referrer"))
		}

		if (wpmDataLayer?.cart && !jQuery.isEmptyObject(wpmDataLayer.cart)) {
			payload.facebook.custom_data = {
				content_type: "product",
				content_ids : wpm.fbGetContentIdsFromCart(),
				value       : wpm.getCartValue(),
				currency    : wpmDataLayer.shop.currency,
			}
		}
	}

	// TikTok
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event     : "InitiateCheckout",
			event_id  : wpm.getRandomEventId(),
			user      : wpm.tiktokGetEventUserData(),
			page      : wpm.tiktokGetEventPageData(),
			properties: wpm.tiktokGetPropertiesFromCart(),
		}
	}

	// Snapchat
	// https://docs.snap.com/api/marketing-api/Conversions-API/Parameters
	if (wpmDataLayer?.pixels?.snapchat?.loaded) {
		payload.snapchat            = wpm.getSnapchatS2SBaseData()
		payload.snapchat.event_name = "START_CHECKOUT"
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("pmw:s2s:begin-checkout", payload)

	/**
	 * Process the server-to-server event
	 */

	wpm.sendEventPayloadToServer(payload)
})

/**
 * Add Payment Info
 */
jQuery(document).on("pmw:add-payment-info", (event) => {

	pmw.console.log("pmw:add-payment-info event fired", {event})

	/**
	 * Prepare the payload
	 */

	let payload = {
		event: "add_payment_info",
	}

	// Facebook
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "AddPaymentInfo",
			event_id        : wpm.getFbRandomEventId(),
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : {},
		}

		if (wpm.retrieveData("referrer")) {
			payload.facebook.referrer_url = wpm.makeFullUrl(wpm.retrieveData("referrer"))
		}
	}

	// TikTok
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event     : "AddPaymentInfo",
			event_id  : wpm.getRandomEventId(),
			user      : wpm.tiktokGetEventUserData(),
			page      : wpm.tiktokGetEventPageData(),
		}
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("pmw:s2s:add-payment-info", payload)

	/**
	 * Process the server-to-server event
	 */

	wpm.sendEventPayloadToServer(payload)
})

/**
 * Add to wishlist event
 */
jQuery(document).on("pmw:add-to-wishlist", (event, product) => {

	pmw.console.log("pmw:add-to-wishlist event fired", {event, product})

	/**
	 * Prepare the payload
	 */

	let payload = {
		event  : "add_to_wishlist",
		product: product,
	}

	// Facebook
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "AddToWishlist",
			event_id        : wpm.getFbRandomEventId(),
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : wpm.fbGetProductDataForCapiEvent(product),
		}

		if (wpm.retrieveData("referrer")) {
			payload.facebook.referrer_url = wpm.makeFullUrl(wpm.retrieveData("referrer"))
		}
	}

	// TikTok
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event     : "AddToWishlist",
			event_id  : wpm.getRandomEventId(),
			user      : wpm.tiktokGetEventUserData(),
			page      : wpm.tiktokGetEventPageData(),
			properties: {
				value       : product.price * product.quantity,
				currency    : product.currency,
				content_type: "product",
				contents    : [{
					content_id  : wpm.getProductIdForSpecificPixel(product, "tiktok"),
					content_name: product.name,
					quantity    : product.quantity,
					price       : product.price,
				}],
			},
		}
	}

	// Snapchat
	// https://docs.snap.com/api/marketing-api/Conversions-API/Parameters
	// https://businesshelp.snapchat.com/s/article/pixel-direct-implementation
	if (wpmDataLayer?.pixels?.snapchat?.loaded) {
		payload.snapchat             = wpm.getSnapchatS2SBaseData()
		payload.snapchat.event_name  = "ADD_TO_WISHLIST"
		payload.snapchat.custom_data = wpm.getSnapchatCustomDataForProduct(product)
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("pmw:s2s:add-to-wishlist", payload)

	/**
	 * Process the server-to-server event
	 */

	wpm.sendEventPayloadToServer(payload)
})

/**
 * View Item event
 */
jQuery(document).on("pmw:view-item", (event, product = null) => {

	pmw.console.log("wpmViewItem event fired", {event, product})

	/**
	 * Prepare the payload
	 */

	let payload = {
		event  : "view_item",
		product: product,
	}

	// Facebook
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "ViewContent",
			event_id        : wpm.getFbRandomEventId(),
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : {},
		}

		if (wpm.retrieveData("referrer")) {
			payload.facebook.referrer_url = wpm.makeFullUrl(wpm.retrieveData("referrer"))
		}

		if (product) {
			payload.facebook.custom_data = wpm.fbGetProductDataForCapiEvent(product)
		}
	}

	// Pinterest
	// https://developers.pinterest.com/docs/conversions/best/
	// https://developers.pinterest.com/docs/api/v5/#operation/events/create
	// https://developers.pinterest.com/docs/conversions/event/
	if (wpmDataLayer?.pixels?.pinterest?.loaded) {

		payload.pinterest = wpm.getPinterestS2SBaseData()

		payload.pinterest.event_name = "pagevisit"

		if (product) {
			payload.pinterest.custom_data = wpm.pinterestGetProductDataForCapiEvent(product)
		}
	}

	// TikTok
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event   : "ViewContent",
			event_id: wpm.getRandomEventId(),
			user    : wpm.tiktokGetEventUserData(),
			page    : wpm.tiktokGetEventPageData(),
		}

		if (product) {
			payload.tiktok.properties = {
				value       : product.price * product.quantity,
				currency    : product.currency,
				content_type: "product",
				contents    : [{
					content_id  : wpm.getProductIdForSpecificPixel(product, "tiktok"),
					content_name: product.name,
					quantity    : product.quantity,
					price       : product.price,
				}],
			}
		}
	}

	// Snapchat
	// https://docs.snap.com/api/marketing-api/Conversions-API/Parameters
	// https://businesshelp.snapchat.com/s/article/pixel-direct-implementation
	if (wpmDataLayer?.pixels?.snapchat?.loaded) {
		payload.snapchat            = wpm.getSnapchatS2SBaseData()
		payload.snapchat.event_name = "VIEW_CONTENT"

		if (product) {
			payload.snapchat.custom_data = wpm.getSnapchatCustomDataForProduct(product)
		}
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("pmw:s2s:view-item", payload)

	/**
	 * Process the server-to-server event
	 */

	wpm.sendEventPayloadToServer(payload)
})

/**
 * View category event
 */
jQuery(document).on("pmw:view-category", (event, product = null) => {

	pmw.console.log("pmw:view-category event fired", {event, product})

	/**
	 * Prepare the payload
	 */

	let payload = {
		event: "view_category",
	}

	// Pinterest
	// https://developers.pinterest.com/docs/conversions/best/
	// https://developers.pinterest.com/docs/api/v5/#operation/events/create
	// https://developers.pinterest.com/docs/conversions/event/
	if (wpmDataLayer?.pixels?.pinterest?.loaded) {

		payload.pinterest = wpm.getPinterestS2SBaseData()

		payload.pinterest.event_name = "viewcategory"
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("pmw:s2s:view-category", payload)

	/**
	 * Process the server-to-server event
	 */

	wpm.sendEventPayloadToServer(payload)
})

/**
 * Search event
 */
jQuery(document).on("pmw:search", (event) => {

	pmw.console.log("pmw:search event fired", {event})

	/**
	 * Prepare the payload
	 */

	let payload = {
		event: "search",
	}

	// Facebook
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "Search",
			event_id        : wpm.getFbRandomEventId(),
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : {
				search_string: wpm.getSearchTermFromUrl(),
			},
		}

		if (wpm.retrieveData("referrer")) {
			payload.facebook.referrer_url = wpm.makeFullUrl(wpm.retrieveData("referrer"))
		}
	}

	// Pinterest
	// https://developers.pinterest.com/docs/conversions/best/
	// https://developers.pinterest.com/docs/api/v5/#operation/events/create
	// https://developers.pinterest.com/docs/conversions/event/
	if (wpmDataLayer?.pixels?.pinterest?.loaded) {

		payload.pinterest = wpm.getPinterestS2SBaseData()

		payload.pinterest.event_name  = "search"
		payload.pinterest.custom_data = {
			search_string: wpm.getSearchTermFromUrl(),
		}
	}

	// TikTok
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event     : "Search",
			event_id  : wpm.getRandomEventId(),
			user      : wpm.tiktokGetEventUserData(),
			page      : wpm.tiktokGetEventPageData(),
			properties: {
				query: wpm.getSearchTermFromUrl(),
			},
		}
	}

	// Snapchat
	// https://docs.snap.com/api/marketing-api/Conversions-API/Parameters
	// https://businesshelp.snapchat.com/s/article/pixel-direct-implementation
	if (wpmDataLayer?.pixels?.snapchat?.loaded) {
		payload.snapchat             = wpm.getSnapchatS2SBaseData()
		payload.snapchat.event_name  = "SEARCH"
		payload.snapchat.custom_data = {
			search_string: wpm.getSearchTermFromUrl(),
		}
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("pmw:s2s:search", payload)

	/**
	 * Process the server-to-server event
	 */

	wpm.sendEventPayloadToServer(payload)
})

/**
 * Place order event
 */
jQuery(document).on("pmw:place-order", (event) => {

	pmw.console.log("pmw:place-order event fired", {event})

	/**
	 * Prepare the payload
	 */

	let payload = {
		event: "place_order",
	}

	// TikTok
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event     : "PlaceAnOrder",
			event_id  : wpm.getRandomEventId(),
			user      : wpm.tiktokGetEventUserData(),
			page      : wpm.tiktokGetEventPageData(),
			properties: wpm.tiktokGetPropertiesFromCart(),
		}
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("pmw:s2s:place-order", payload)

	/**
	 * Process the server-to-server event
	 */

	wpm.sendEventPayloadToServer(payload)
})

/**
 * Order received event
 */
jQuery(document).on("pmw:view-order-received-page", (event) => {

	pmw.console.log("pmw:view-order-received-page event fired", {event})

	/**
	 * Prepare the payload
	 */

	let payload = {
		event: "order_received",
	}

	// Facebook
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "Purchase",
			event_id        : wpmDataLayer.order.id.toString(),
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : {
				content_type: "product",
				value       : wpmDataLayer.order.value.marketing,
				currency    : wpmDataLayer.order.currency,
				content_ids : wpm.facebookContentIds(),
			},
		}

		if (wpm.retrieveData("referrer")) {
			payload.facebook.referrer_url = wpm.makeFullUrl(wpm.retrieveData("referrer"))
		}
	}

	// Pinterest
	// https://developers.pinterest.com/docs/conversions/best/
	// https://developers.pinterest.com/docs/api/v5/#operation/events/create
	// https://developers.pinterest.com/docs/conversions/event/
	if (wpmDataLayer?.pixels?.pinterest?.loaded) {

		payload.pinterest = wpm.getPinterestS2SBaseData()

		payload.pinterest.event_name = "checkout"
	}

	// TikTok
	// https://ads.tiktok.com/help/article/standard-events-parameters
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event     : "CompletePayment",
			event_id  : wpmDataLayer.order.id.toString(),
			user      : wpm.tiktokGetEventUserData(),
			page      : wpm.tiktokGetEventPageData(),
			properties: {
				value       : wpmDataLayer.order.value.marketing,
				currency    : wpmDataLayer.order.currency,
				content_type: "product",
				contents    : wpm.getTikTokOrderItemIds(),
			},
		}
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("pmw:s2s:view-order-received-page", payload)

	/**
	 * Process the server-to-server event
	 */

	// ! No server-to-server event is sent for this event because it is compiled and sent from the server directly
})

/**
 * Login event
 */
jQuery(document).on("pmw:login", () => {

	pmw.console.log("pmw:login event fired")

	/**
	 * Prepare the payload
	 */

	let payload = {
		event: "login",
	}

	// Snapchat
	// https://docs.snap.com/api/marketing-api/Conversions-API/Parameters
	// https://businesshelp.snapchat.com/s/article/pixel-direct-implementation
	if (wpmDataLayer?.pixels?.snapchat?.loaded) {
		payload.snapchat            = wpm.getSnapchatS2SBaseData()
		payload.snapchat.event_name = "LOGIN"
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("pmw:s2s:login", payload)

	/**
	 * Process the server-to-server event
	 */

	wpm.sendEventPayloadToServer(payload)
})

/**
 * Run WooCommerce specific functions
 */
jQuery(document).on("pmw:ready", async () => {

	// Only run if WooCommerce is active
	if (!wpm.isWooCommerceActive()) {
		return
	}

	/**
	 * Run as soon as wpm namespace is loaded
	 */

	// Watch for products visible in viewport
	wpm.startIntersectionObserverToWatch()

	// Watch for lazy loaded products
	wpm.startProductsMutationObserverToWatch()
})
